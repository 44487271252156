<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Resume Rawat Jalan
        </div>
        <div class="card">
          <div class="card-body background-blue">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Waktu Pemeriksaan
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.checkup_time }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xl-6">
              <div class="h4 mb-2 font-weight-bold text-color-green">
                Resume Medis
              </div>
              <div class="card">
                <div class="card-body">
                  <a-button
                    class="my-3"
                    style="
                      border-radius: 20px;
                      border: 1px solid #98a2b3;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <img
                      src="/resources/images/pdf.png"
                      alt=""
                      style="width: 16px; height: 16px; margin-right: 5px"
                    />
                    <p class="m-0" style="color: #98a2b3">PDF</p></a-button
                  >
                  <div class="h5 font-weight-bold">Catatan SOAP</div>

                  <div class="font-weight-bold SOAP-title-color">
                    Subjective
                  </div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.subjective)
                    "
                  ></div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">Objective</div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.objective)
                    "
                  ></div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">
                    Assessment
                  </div>
                  <div
                    v-html="
                      formatText(this.patientVisitData.DoctorNote.assessment)
                    "
                  ></div>
                  <div class="font-weight-bold">Diagnosis Primer:</div>
                  <div>
                    •
                    {{ this.patientVisitData.DoctorNote.Conditions[0].code }}
                    |
                    {{ this.patientVisitData.DoctorNote.Conditions[0].STR }}
                  </div>
                  <div class="font-weight-bold">Diagnosis Sekunder:</div>
                  <div
                    v-for="(
                      condition, idx
                    ) in this.patientVisitData.DoctorNote.Conditions.slice(1)"
                    :key="'A' + idx"
                  >
                    • {{ condition.code }} | {{ condition.STR }}
                  </div>
                  <div
                    v-if="
                      this.patientVisitData.DoctorNote.Conditions.length === 1
                    "
                  >
                    -
                  </div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">Plan</div>
                  <div
                    v-html="formatText(this.patientVisitData.DoctorNote.plan)"
                  ></div>
                  <div class="font-weight-bold">Kode Tindakan:</div>
                  <div
                    v-for="(action, idx) in this.patientVisitData.DoctorNote
                      .Procedures"
                    :key="'B' + idx"
                  >
                    • {{ action.code }} | {{ action.STR }}
                  </div>
                  <div
                    v-if="
                      this.patientVisitData.DoctorNote.Procedures.length === 0
                    "
                  >
                    -
                  </div>
                  <br />
                  <a-divider />
                  <div class="h5 font-weight-bold">Anamnesis</div>

                  <div class="font-weight-bold">Keluhan Utama</div>
                  <div
                    v-for="(keluhan, index) in this.patientVisitData.Anamnesis
                      .ChiefComplaints"
                    :key="'C' + index"
                  >
                    <div>• {{ keluhan.keterangan }}</div>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Penyakit</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th
                          style="
                            width: 200px;
                            height: 32px;
                            padding-right: 15px;
                          "
                        >
                          Kategori
                        </th>
                        <th>Nama Penyakit</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(riwayat, index) in this.patientVisitData
                            .Anamnesis.IllnessHistories"
                          :key="'D' + index"
                        >
                          <td v-if="riwayat.type === 'personal'">
                            Riwayat Penyakit Pribadi
                          </td>
                          <td v-if="riwayat.type === 'family'">
                            Riwayat Penyakit Keluarga
                          </td>
                          <td>{{ riwayat.display }}</td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.IllnessHistories
                              .length === 0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Alergi</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th
                          style="
                            width: 200px;
                            height: 32px;
                            padding-right: 15px;
                          "
                        >
                          Jenis Alergi
                        </th>
                        <th>Nama Alergen</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(allergy, idx) in this.patientVisitData
                            .Anamnesis.Allergies"
                          :key="'E' + idx"
                        >
                          <!-- <td>{{ allergy.type }}</td> -->
                          <td v-if="allergy.type === 1">Medication</td>
                          <td v-if="allergy.type === 2">Food</td>
                          <td v-if="allergy.type === 3">Environment</td>
                          <td v-if="allergy.type === 4">Biologic</td>
                          <td>{{ allergy.display }}</td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.Allergies.length ===
                            0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <div class="font-weight-bold">Riwayat Pengobatan</div>
                  <div>
                    <table class="table">
                      <thead>
                        <th style="height: 32px; padding-right: 15px">
                          Nama Obat
                        </th>
                        <th>Dosis dan Frekuensi</th>
                        <th>Status</th>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(medication, idx) in this.patientVisitData
                            .Anamnesis.MedicationStatements"
                          :key="'F' + idx"
                        >
                          <td>{{ medication.name }}</td>
                          <td>{{ medication.dosage }}</td>
                          <td>
                            {{
                              medicineStatus.find(
                                (item) => item.value === medication.status,
                              ).label
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            this.patientVisitData.Anamnesis.MedicationStatements
                              .length === 0
                          "
                        >
                          <td>Tidak ada data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a-divider />
                  <div class="h5 font-weight-bold">Penunjang</div>
                  <div v-if="this.patientVisitDocuments.length === 0">
                    Pasien ini belum memiliki dokumen penunjang.
                  </div>
                  <div
                    v-for="(file, idx) in this.patientVisitDocuments"
                    :key="'G' + idx"
                  >
                    <div>
                      Diunggah oleh {{ file.created_by_name }} pada
                      {{ formatDateTimeFull(file.VisitDocument.createdAt) }}
                    </div>
                    <div v-if="file.media_mime_type !== 'application/pdf'">
                      <div class="image-container">
                        <object
                          :data="file.uri"
                          :type="file.media_mime_type"
                          width="400px"
                          min-height="100%"
                        ></object>
                        <button
                          class="zoom-button"
                          @click="openViewer(file.uri, file)"
                        >
                          <img
                            src="resources/images/zoom-icon.svg"
                            alt="Zoom In"
                            width="20px"
                            height="20px"
                          />
                        </button>
                      </div>
                    </div>
                    <div v-else style="min-width: 50vw">
                      <object
                        :data="file.uri"
                        :type="file.media_mime_type"
                        width="100%"
                        height="700px"
                      ></object>
                    </div>
                    <a :href="file.uri" target="_blank">{{ file.name }}</a>
                    <br /><br />
                  </div>
                  <a-divider />
                  <div class="h5 font-weight-bold">Obat</div>

                  <div class="font-weight-bold SOAP-title-color">Resep</div>
                  <div
                    v-for="(medication, index) in this.patientVisitData
                      .DoctorNote.MedicationRequests"
                    :key="'H' + index"
                  >
                    • {{ medication.name }} | {{ medication.quantity }} ({{
                      medication.dosage
                    }}
                    x {{ medication.frequency }},
                    {{ medication.use_morning === true ? "Pagi" : "" }}
                    {{ medication.use_noon === true ? "Siang" : "" }}
                    {{ medication.use_night === true ? "Malam" : "" }})
                  </div>
                  <br />
                  <div class="font-weight-bold SOAP-title-color">
                    Catatan Resep
                  </div>
                  <div
                    v-if="this.patientVisitData.DoctorNote.medication_note"
                    v-html="
                      formatText(
                        this.patientVisitData.DoctorNote.medication_note,
                      )
                    "
                  ></div>
                  <div v-else>Tidak Ada</div>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="h4 mb-2 font-weight-bold text-color-green">
                {{
                  this.patientVisitData.status !== "Selesai Verifikasi Kode"
                    ? ""
                    : "Hasil"
                }}
                Verifikasi Kode Diagnosis dan Tindakan
              </div>
              <div class="card">
                <div
                  class="card-body"
                  v-if="
                    this.patientVisitData.status !== 'Selesai Verifikasi Kode'
                  "
                >
                  <div>
                    <p class="font-weight-bold">
                      Diagnosis Primer<span class="text-danger">*</span>
                    </p>

                    <a-form-item
                      v-for="(input, index) in primary_condition"
                      :key="'I' + index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="d-flex" style="width: 90%">
                          <a-select
                            show-search
                            @search="searchICD10"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                            @change="handleChangeICD10"
                          >
                            <a-select-option
                              v-for="(item, idx) in ICD10List"
                              :key="'J' + idx"
                              :value="item.skri + ' | ' + item.STR"
                            >
                              {{ item.skri }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                          <div
                            class="d-flex align-items-center justify-content-between ml-4"
                          >
                            <img
                              src="resources/images/x-black.svg"
                              alt="Icon"
                              style="height: 20px; width: 20px; cursor: pointer"
                              @click="removePrimaryInput(index)"
                            />
                          </div>
                        </div>
                      </div>
                    </a-form-item>

                    <div
                      @click="addPrimaryInput"
                      v-if="primary_condition.length === 0"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-10</a>
                    </div>
                  </div>
                  <div v-if="this.primary_recommendations.length > 0">
                    <div class="font-weight-bold">
                      Rekomendasi Diagnosis ICD-10
                    </div>
                    <div>
                      Tekan kode ICD-10 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in primary_recommendations"
                        :key="'K' + index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addPrimaryConditions(text)"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <a-divider />

                  <!-- Sekunder -->
                  <div class="mt-4">
                    <p class="font-weight-bold">
                      Diagnosis Sekunder<span class="text-danger">*</span>
                    </p>

                    <a-form-item
                      v-for="(input, index) in secondary_condition"
                      :key="'L' + index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="d-flex">
                          <a-select
                            show-search
                            @search="searchICD10"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                            @change="handleChangeICD10"
                          >
                            <a-select-option
                              v-for="(item, idx) in ICD10List"
                              :key="'M' + idx"
                              :value="item.skri + ' | ' + item.STR"
                            >
                              {{ item.skri }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                          <div
                            class="d-flex align-items-center justify-content-between ml-4"
                          >
                            <img
                              src="resources/images/x-black.svg"
                              alt="Icon"
                              style="height: 20px; width: 20px; cursor: pointer"
                              @click="handleSecondaryInput(index, 'delete')"
                            />
                            <img
                              src="resources/images/up-black.svg"
                              alt="Icon"
                              style="
                                height: 20px;
                                width: 20px;
                                cursor: pointer;
                                margin-left: 15px;
                              "
                              v-if="secondary_condition.length > 1 && index"
                              @click="handleSecondaryInput(index, 'up')"
                            />
                            <img
                              src="resources/images/down-black.svg"
                              alt="Icon"
                              style="
                                height: 20px;
                                width: 20px;
                                cursor: pointer;
                                margin-left: 15px;
                              "
                              v-if="
                                secondary_condition.length > 1 &&
                                index !== secondary_condition.length - 1
                              "
                              @click="handleSecondaryInput(index, 'down')"
                            />
                          </div>
                        </div>
                      </div>
                    </a-form-item>
                    <p>Urutkan kode ICD dari diagnosis yang paling utama</p>
                    <div
                      @click="addSecondaryInput"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-10</a>
                    </div>
                  </div>
                  <div v-if="this.secondary_recommendations.length > 0">
                    <div class="font-weight-bold">Rekomendasi ICD-10</div>
                    <div>
                      Tekan kode ICD-10 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in secondary_recommendations"
                        :key="'N' + index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addSecondaryConditions(text)"
                          v-if="text.status !== 'delete'"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <a-divider />
                  <!-- Tindakan -->
                  <div class="mt-4">
                    <p class="font-weight-bold">Tindakan</p>
                    <a-form-item
                      v-for="(input, index) in tindakan_condition"
                      :key="'O' + index"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="height: 2.5rem"
                      >
                        <div class="d-flex" style="width: 90%">
                          <a-select
                            show-search
                            @search="searchICD9CM"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model="input.code"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            style="min-width: 15rem"
                            @change="handleChangeICD9"
                          >
                            <a-select-option
                              v-for="(item, idx) in ICD9CMList"
                              :key="'P' + idx"
                              :value="item.CODE + ' | ' + item.STR"
                            >
                              {{ item.CODE }} | {{ item.STR }}</a-select-option
                            >
                          </a-select>
                          <div
                            class="d-flex align-items-center justify-content-between ml-4"
                          >
                            <img
                              src="resources/images/x-black.svg"
                              alt="Icon"
                              style="height: 20px; width: 20px; cursor: pointer"
                              @click="handleProcedureInput(index, 'delete')"
                            />
                            <img
                              src="resources/images/up-black.svg"
                              alt="Icon"
                              style="
                                height: 20px;
                                width: 20px;
                                cursor: pointer;
                                margin-left: 15px;
                              "
                              v-if="tindakan_condition.length > 1 && index"
                              @click="handleProcedureInput(index, 'up')"
                            />
                            <img
                              src="resources/images/down-black.svg"
                              alt="Icon"
                              style="
                                height: 20px;
                                width: 20px;
                                cursor: pointer;
                                margin-left: 15px;
                              "
                              v-if="
                                tindakan_condition.length > 1 &&
                                index !== tindakan_condition.length - 1
                              "
                              @click="handleProcedureInput(index, 'down')"
                            />
                          </div>
                        </div>
                      </div>
                    </a-form-item>

                    <div
                      @click="addTindakanInput"
                      class="mt-2 h6 mb-3"
                      style="color: #428a94"
                    >
                      <a>+ Tambah Kode ICD-9</a>
                    </div>
                  </div>
                  <div v-if="this.tindakan_recommendations.length > 0">
                    <div class="font-weight-bold">Rekomendasi ICD-9</div>
                    <div>
                      Tekan kode ICD-9 untuk menambahkan kode ke diagnosis
                    </div>
                    <div class="recommendation-button-container">
                      <div
                        v-for="(text, index) in tindakan_recommendations"
                        :key="'Q' + index"
                      >
                        <a-button
                          class="mb-2"
                          style="border: 1px solid #428a94"
                          shape="round"
                          @click="addTindakanConditions(text)"
                          >{{ text.code }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="card mt-4"
                      style="
                        background-color: #f9fdff;
                        border: none;
                        border-radius: 20px;
                      "
                    >
                      <a-collapse v-model="keyCollapse">
                        <a-collapse-panel
                          key="1"
                          header="Rekomendasi Perbaikan"
                          v-if="rekomendasiBeritaAcara.length"
                        >
                          <p>
                            <strong>Menurut BA Kesepakatan Koding BPJS</strong>
                          </p>
                          <div
                            v-for="(item, idx) in rekomendasiBeritaAcara"
                            :key="'AB' + idx"
                            class="mt-3"
                          >
                            <p>
                              Aspek koding untuk
                              <strong>{{ item.type }} {{ item.code }}</strong>
                              (BA Tahun {{ item.tahun }})
                            </p>
                            <!-- Aspek Koding -->
                            <p v-if="item.aspekKoding.paragraph">
                              {{ item.aspekKoding.paragraph }}
                            </p>
                            <div
                              v-for="(point, index) in item.aspekKoding.list"
                              :key="index"
                            >
                              {{ point }}
                            </div>

                            <!-- Aspek Medis -->
                            <p v-if="item.aspekMedis.paragraph">
                              {{ item.aspekMedis.paragraph }}
                            </p>
                            <div
                              v-for="(point, index) in item.aspekMedis.list"
                              :key="index"
                            >
                              {{ point }}
                            </div>

                            <!-- Perhatian Khusus -->
                            <p v-if="item.perhatianKhusus.paragraph">
                              {{ item.perhatianKhusus.paragraph }}
                            </p>
                            <div
                              v-for="(point, index) in item.perhatianKhusus
                                .list"
                              :key="index"
                            >
                              {{ point }}
                            </div>

                            <!-- Rekomendasi -->
                            <p v-if="item.rekomendasi.paragraph">
                              {{ item.rekomendasi.paragraph }}
                            </p>
                            <div
                              v-for="(point, index) in item.rekomendasi.list"
                              :key="index"
                            >
                              {{ point }}
                            </div>
                            <a-divider></a-divider>
                          </div>
                        </a-collapse-panel>
                        <a-collapse-panel key="2" header="Rekomendasi AI">
                          <div v-if="!initialLoading">
                            <!-- <p class="font-weight-bold">Rekomendasi AI</p> -->
                            <p class="font-weight-bold">Diagnosis:</p>
                            <div v-if="this.primary_recommendations.length">
                              Primer: <br />
                              <ul>
                                <li
                                  v-for="(el, idx) in this
                                    .primary_recommendations"
                                  :key="'R' + idx"
                                >
                                  {{ el.code + " (" + el.reason + ")" }}.
                                </li>
                              </ul>
                            </div>
                            <div v-if="this.secondary_recommendations.length">
                              Sekunder: <br />
                              <ul>
                                <li
                                  v-for="(el, idx) in this
                                    .secondary_recommendations"
                                  :key="'S' + idx"
                                >
                                  {{ el.code + " (" + el.reason + ")" }}.
                                </li>
                              </ul>
                            </div>
                            <div v-if="this.tindakan_recommendations.length">
                              <p class="font-weight-bold m-0 mb-2">
                                Tindakan/Prosedur:
                              </p>
                              <ul>
                                <li
                                  v-for="(el, idx) in this
                                    .tindakan_recommendations"
                                  :key="'T' + idx"
                                >
                                  {{ el.code + " (" + el.reason + ")" }}.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column align-items-center my-4"
                            v-else
                          >
                            <a-spin :indicator="indicator" class="mb-3" />
                            <p class="h5 font-weight-bold">Mohon Tunggu</p>
                            <p class="h5">
                              Rekomendasi sedang dibuat berdasarkan resume
                            </p>
                          </div>
                          <a-button
                            class="my-3"
                            style="
                              border-radius: 20px;
                              border: 1px solid #428a94;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 221px;
                              height: 33px;
                            "
                            @click="generateNewRecommendations"
                            :loading="ICD10Loading || ICD9CMLoading"
                            v-if="!initialLoading"
                          >
                            <img
                              src="/resources/images/spark.png"
                              alt=""
                              style="
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                                margin-left: 5px;
                              "
                              v-if="!ICD10Loading && !ICD9CMLoading"
                            />
                            <p class="m-0 ml-1" style="color: #428a94">
                              Buat Ulang Rekomendasi
                            </p></a-button
                          >
                        </a-collapse-panel>
                      </a-collapse>
                    </div>

                    <a-button
                      class="my-3"
                      style="
                        border-radius: 20px;
                        border: 1px solid #428a94;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 221px;
                        height: 33px;
                        background-color: #428a94;
                      "
                      @click="submitData"
                    >
                      <img
                        src="/resources/images/save.png"
                        alt=""
                        style="width: 16px; height: 16px; margin-right: 5px"
                      />
                      <p class="m-0" style="color: white">
                        Simpan Hasil Verifikasi
                      </p></a-button
                    >
                  </div>
                </div>
                <div class="card-body" v-else>
                  <div v-if="detailLogCode.id">
                    <div>
                      <p class="font-weight-bold SOAP-title-color mb-2">
                        Waktu Verifikasi
                      </p>
                      <p class="">{{ detailLogCode.date }}</p>
                    </div>
                    <div class="mt-2">
                      <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                        Petugas Koder
                      </p>
                      <p class="">
                        {{
                          detailLogCode.User.name ? detailLogCode.User.name : ""
                        }}
                      </p>
                    </div>
                    <div class="mt-2">
                      <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                        Diagnosis Primer
                      </p>
                      <p
                        class=""
                        v-if="
                          !detailLogCode.oldPrimary.length &&
                          !detailLogCode.primary.length
                        "
                      >
                        Tidak ada perubahan
                      </p>
                      <div v-else>
                        <p class="m-0 mb-2">
                          Kode lama oleh
                          {{
                            detailLogCode.oldPrimary[0].User.Roles[0].name
                              ? detailLogCode.oldPrimary[0].User.Roles[0].name
                              : ""
                          }}:
                        </p>
                        <ul class="m-0">
                          <li
                            v-for="(item, idx) in detailLogCode.oldPrimary"
                            :key="'U' + idx"
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                        <div class="mt-2">
                          <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                          <ul class="m-0">
                            <li
                              v-for="(item, idx) in detailLogCode.primary"
                              :key="'V' + idx"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                        Diagnosis Sekunder
                      </p>
                      <p
                        class=""
                        v-if="
                          !detailLogCode.oldSecondary.length &&
                          !detailLogCode.secondary.length
                        "
                      >
                        Tidak ada perubahan
                      </p>
                      <div v-else>
                        <p class="m-0 mb-2">
                          Kode lama oleh
                          {{
                            detailLogCode.oldSecondary[0].User.Roles[0].name
                          }}:
                        </p>
                        <ul class="m-0">
                          <li
                            v-for="(item, idx) in detailLogCode.oldSecondary"
                            :key="'W' + idx"
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                        <div class="mt-2">
                          <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                          <ul class="m-0">
                            <li
                              v-for="(item, idx) in detailLogCode.secondary"
                              :key="'X' + idx"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <p class="font-weight-bold SOAP-title-color mt-3 mb-2">
                        Tindakan
                      </p>
                      <p
                        class=""
                        v-if="
                          !detailLogCode.oldProcedure.length &&
                          !detailLogCode.procedure.length
                        "
                      >
                        Tidak ada perubahan
                      </p>
                      <div v-else>
                        <p class="m-0 mb-2">
                          Kode lama oleh
                          {{
                            detailLogCode.oldProcedure[0].User.Roles[0].name
                          }}:
                        </p>
                        <ul class="m-0">
                          <li
                            v-for="(item, idx) in detailLogCode.oldProcedure"
                            :key="'Y' + idx"
                          >
                            {{ item.name }}
                          </li>
                        </ul>
                        <div class="mt-2">
                          <p class="m-0 mb-2">Kode baru oleh Koder:</p>
                          <ul class="m-0">
                            <li
                              v-for="(item, idx) in detailLogCode.procedure"
                              :key="'Z' + idx"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPatientVisit,
  getDokumenPenunjang,
  saveCoderNote,
} from "@/services/axios/api/kunjungan"
import { getCodeLogs } from "@/services/axios/api/fileReview"
import {
  searchICD10Code,
  searchICD9CMCode,
  generateICD10Code,
  generateICDCodes,
  generateICD9CMCode,
  getBeritaAcara,
} from "@/services/axios/api/icdCodes"
import { getUserProfileFromId } from "@/services/axios/api/auth"
import Viewer from "viewerjs"
import "viewerjs/dist/viewer.css"

export default {
  data() {
    return {
      patientVisitData: {},
      patientVisitDocuments: [],
      visit_id: "",
      medicineStatus: [
        { label: "Active", value: "active" },
        { label: "Completed", value: "completed" },
        { label: "Entered in Error", value: "entered-in-error" },
        { label: "Intended", value: "intended" },
        { label: "Stopped", value: "stopped" },
        { label: "Draft", value: "draft" },
        { label: "On Hold", value: "on-hold" },
        { label: "Unknown", value: "unknown" },
        { label: "Not Taken", value: "not-taken" },
      ],
      conditions_autoGenerated: [],
      primary_condition: [],
      old_primary_condition: [],
      primary_recommendations: [],
      secondary_recommendations: [],
      secondary_condition: [],
      old_secondary_condition: [],
      ICD10List: [],
      tindakan_recommendations: [],
      tindakan_condition: [],
      old_tindakan_condition: [],
      ICD9CMList: [],
      codeLogList: [],
      ICD10Loading: false,
      ICD9CMLoading: false,
      initialLoading: false,
      indicator: <a-icon type="loading" style="font-size: 48px" spin />,
      detailLogCode: {},
      keyCollapse: ["1", "2"],
      beritaAcara: [],
      rekomendasiBeritaAcara: [],
    }
  },
  created() {
    this.visit_id = this.$route.params.id
    this.fetchData()
  },

  methods: {
    formatToList(text) {
      if (!text) return { paragraph: "", list: [] }

      // Regex: Tangkap paragraf pertama + list numerik di awal baris (1. 2. 3.)
      const regex = /(?:^|\n)(\d+\.\s+.*?)(?=\n\d+\.|\n|$)/gs
      const matches = [...text.matchAll(regex)].map((match) => match[1])

      // Pisahkan paragraf awal (jika tidak ada angka di awal, anggap paragraf)
      let paragraph = text
      if (matches.length > 0) {
        paragraph = text.split(matches[0])[0].trim()
      }

      return {
        paragraph: paragraph || "", // Pastikan tetap ada paragraf jika kosong
        list: matches,
      }
    },
    handleChangeICD10(e) {
      // console.log("masuksini kan ya")
      const code = e.split("|")[0].trim()
      // console.log(this.beritaAcara)
      // console.log(code)
      this.handleBeritaAcara(code, "10")
    },
    handleChangeICD9(e) {
      // console.log("masuksini kan ya")
      const code = e.split("|")[0].trim()
      // console.log(this.beritaAcara)
      // console.log(code)
      this.handleBeritaAcara(code, "9")
    },
    handleBeritaAcara(code, type) {
      for (let i = 0; i < this.beritaAcara.length; i++) {
        // console.log(this.beritaAcara[i])
        if (type === "10") {
          if (this.beritaAcara[i].icd10.length) {
            for (let x = 0; x < this.beritaAcara[i].icd10.length; x++) {
              const baCode = this.beritaAcara[i].icd10[x]
              if (baCode === code) {
                // console.log("masok")
                console.log(this.beritaAcara[i])
                this.beritaAcara[i].code = code
                this.beritaAcara[i].type = "Diagnosis"
                this.beritaAcara[i].aspekKoding = this.formatToList(
                  this.beritaAcara[i].aspekKoding,
                )
                this.beritaAcara[i].aspekMedis = this.formatToList(
                  this.beritaAcara[i].aspekMedis,
                )
                this.beritaAcara[i].perhatianKhusus = this.formatToList(
                  this.beritaAcara[i].perhatianKhusus,
                )
                this.beritaAcara[i].rekomendasi = this.formatToList(
                  this.beritaAcara[i].rekomendasi,
                )

                this.rekomendasiBeritaAcara.push(this.beritaAcara[i])
              }
            }
          }
        } else {
          if (this.beritaAcara[i].icd9.length) {
            for (let x = 0; x < this.beritaAcara[i].icd9.length; x++) {
              const baCode = this.beritaAcara[i].icd9[x]
              if (baCode === code) {
                // console.log("masok")
                console.log(this.beritaAcara[i])
                this.beritaAcara[i].code = code
                this.beritaAcara[i].type = "Prosedur"

                this.beritaAcara[i].aspekKoding = this.formatToList(
                  this.beritaAcara[i].aspekKoding,
                )
                this.beritaAcara[i].aspekMedis = this.formatToList(
                  this.beritaAcara[i].aspekMedis,
                )
                this.beritaAcara[i].perhatianKhusus = this.formatToList(
                  this.beritaAcara[i].perhatianKhusus,
                )
                this.beritaAcara[i].rekomendasi = this.formatToList(
                  this.beritaAcara[i].rekomendasi,
                )

                this.rekomendasiBeritaAcara.push(this.beritaAcara[i])
              }
            }
          }
        }
      }
    },
    async fetchData() {
      this.initialLoading = true
      await this.getPatientVisitData()
      const { data: logsData } = await getCodeLogs(this.visit_id)
      const { data: dataBA } = await getBeritaAcara()
      this.beritaAcara = dataBA.beritaAcara
      // console.log(dataBA)
      // console.log(logsData.logs, "inilogdata")
      const codeLogList = logsData.logs.map((e) => {
        console.log(e, "inilogdetail")
        const oldPrimary = e.CodeLogDetails.filter(
          (el) => el.codeType === "primary" && el.versionType === "old",
        )
        const primary = e.CodeLogDetails.filter(
          (el) => el.codeType === "primary" && el.versionType === "new",
        )
        const oldSecondary = e.CodeLogDetails.filter(
          (el) => el.codeType === "secondary" && el.versionType === "old",
        )
        const secondary = e.CodeLogDetails.filter(
          (el) => el.codeType === "secondary" && el.versionType === "new",
        )
        const oldProcedure = e.CodeLogDetails.filter(
          (el) => el.codeType === "procedure" && el.versionType === "old",
        )
        const procedure = e.CodeLogDetails.filter(
          (el) => el.codeType === "procedure" && el.versionType === "new",
        )
        const formattedDate = new Intl.DateTimeFormat("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(e.createdAt))
        return {
          ...e,
          primary,
          oldPrimary,
          secondary,
          oldSecondary,
          procedure,
          oldProcedure,
          date: formattedDate,
        }
      })
      this.detailLogCode = codeLogList[0]

      // console.log(this.patientVisitData.DoctorNote.Conditions)
      if (this.patientVisitData.DoctorNote.Conditions.length) {
        const primaryCondition =
          this.patientVisitData.DoctorNote.Conditions.filter(
            (e) => e.condition_type === "primary",
          )
        const secondaryCondition =
          this.patientVisitData.DoctorNote.Conditions.filter(
            (e) => e.condition_type === "secondary",
          )
        this.handleBeritaAcara(primaryCondition[0].code, "10")

        const codePrimary = `${primaryCondition[0].code} | ${primaryCondition[0].STR}`
        this.primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })
        this.old_primary_condition.push({
          ...primaryCondition[0],
          code: codePrimary,
        })
        secondaryCondition.forEach((e) => {
          const codeSecondary = `${e.code} | ${e.STR}`
          this.handleBeritaAcara(e.code, "10")
          this.secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
          this.old_secondary_condition.push({
            ...e,
            code: codeSecondary,
          })
        })
      }
      if (this.patientVisitData.DoctorNote.Procedures.length) {
        this.tindakan_condition =
          this.patientVisitData.DoctorNote.Procedures.map((e) => {
            const codeTindakan = `${e.code} | ${e.STR}`
            this.handleBeritaAcara(e.code, "9")
            return {
              ...e,
              code: codeTindakan,
            }
          })
        this.old_tindakan_condition =
          this.patientVisitData.DoctorNote.Procedures.map((e) => {
            const codeTindakan = `${e.code} | ${e.STR}`
            return {
              ...e,
              code: codeTindakan,
            }
          })
      }
      const primaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === "primary",
      )
      const secondaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === "secondary",
      )
      // await this.generateICD10(
      //   this.patientVisitData.DoctorNote.assessment,
      //   primaryCode,
      //   secondaryCode,
      // )
      // await this.generateICD9CM(this.patientVisitData.DoctorNote.plan)
      await this.generateICD(
        this.patientVisitData.DoctorNote.assessment,
        this.patientVisitData.DoctorNote.plan,
        primaryCode,
        secondaryCode,
      )
      this.initialLoading = false
    },
    addSecondaryInput() {
      this.secondary_condition.push({ code: "" })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },
    addTindakanInput() {
      this.tindakan_condition.push({ code: "" })
      // console.log(this.secondary_conditions, 'secondary_conditions')
    },

    addPrimaryConditions(text) {
      const newObj = {
        ...text,
      }
      console.log(text, "iniliat")
      const newCondition = [newObj]
      this.primary_condition = newCondition
    },
    addSecondaryConditions(text) {
      const newObj = {
        ...text,
      }
      this.secondary_condition.push(newObj)
    },
    addTindakanConditions(text) {
      const newObj = {
        ...text,
        status: "preparation",
      }
      this.tindakan_condition.push(newObj)
    },
    addPrimaryInput() {
      this.primary_condition.push({ code: "" })
      // console.log(this.primary_condition, 'primary_condition')
    },
    setTab() {
      const role = window.localStorage.getItem("role")
      if (role === "perawat") {
        return "1"
      } else if (role === "dokter") {
        if (this.patientVisitData.status === "Selesai") {
          return "2"
        } else {
          return "1"
        }
      } else {
        return "1"
      }
    },
    handleSecondaryInput(index, type) {
      switch (type) {
        case "up":
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.secondary_condition[index]
            this.$set(
              this.secondary_condition,
              index,
              this.secondary_condition[index - 1],
            )
            this.$set(this.secondary_condition, index - 1, temp)
          }
          break
        case "down":
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.secondary_condition.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.secondary_condition[index]
            this.$set(
              this.secondary_condition,
              index,
              this.secondary_condition[index + 1],
            )
            this.$set(this.secondary_condition, index + 1, temp)
          }
          break
        case "delete":
          this.secondary_condition.splice(index, 1)
          break
      }
    },
    handleProcedureInput(index, type) {
      switch (type) {
        case "up":
          if (index > 0) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.tindakan_condition[index]
            this.$set(
              this.tindakan_condition,
              index,
              this.tindakan_condition[index - 1],
            )
            this.$set(this.tindakan_condition, index - 1, temp)
          }
          break
        case "down":
          // Periksa apakah elemen dapat dipindahkan ke bawah
          if (index < this.tindakan_condition.length - 1) {
            // Tukar elemen menggunakan Vue.set untuk menjaga reaktivitas
            const temp = this.tindakan_condition[index]
            this.$set(
              this.tindakan_condition,
              index,
              this.tindakan_condition[index + 1],
            )
            this.$set(this.tindakan_condition, index + 1, temp)
          }
          break
        case "delete":
          this.tindakan_condition.splice(index, 1)
          break
      }
    },
    openViewer(fileUri, file) {
      const imageElement = document.createElement("img")
      imageElement.src = fileUri
      imageElement.style.display = "none"
      document.body.appendChild(imageElement)
      console.log(imageElement, "image element")
      const viewer = new Viewer(imageElement, {
        inline: false,
        navbar: false,
        title: [
          1,
          (imageData) =>
            `${file.name} (${imageData.naturalWidth} x ${imageData.naturalHeight})`,
        ],
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          play: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        viewed() {
          viewer.zoomTo(0.5)
        },
        hidden() {
          document.body.removeChild(imageElement)
        },
      })
      viewer.show()
    },
    formatText(text) {
      return text.replace(/\n/g, "<br>")
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleString("id-ID", options)
    },
    formatDateTime(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, "0")
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
    },
    formatDateTimeFull(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, "0")
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ]
      const month = monthNames[date.getMonth()]
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, "0")
      const minutes = String(date.getMinutes()).padStart(2, "0")
      const seconds = String(date.getSeconds()).padStart(2, "0")

      return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error("Error fetching user name:", error)
        return "Unknown"
      }
    },
    async getHistoryDocumentImageUrl(file, visitId) {
      const response = await getDokumenPenunjang(visitId, file.id)
      const url = URL.createObjectURL(response.data)
      return url
    },
    async getPatientVisitData() {
      const response = await getPatientVisit(this.visit_id)
      this.patientVisitData = response.data.visit

      if (
        this.patientVisitData.Anamnesis === null ||
        this.patientVisitData.Anamnesis.length === 0
      ) {
        this.$notification.error({
          message: "Data Tidak Ditemukan!",
          description: "Data pemeriksaan belum diisi!",
          duration: 5,
        })
        this.$router.go(-1)
        return
      }

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === "Male"
            ? "Laki-laki"
            : "Perempuan",
        payment_method:
          this.patientVisitData.Patient.payment_method === "JKN"
            ? "JKN (BPJS Kesehatan)"
            : this.patientVisitData.Patient.payment_method,
        checkup_time: this.formatDateTime(this.patientVisitData.checkup_time),
        checkup_date: this.formatDate(this.patientVisitData.checkup_time),
        nurse_name: await this.getUserName(
          this.patientVisitData.attending_nurse_id,
        ),
      }
      // this.patientVisitDocuments = this.patientVisitData.documents
      for (const file in response.data.documents) {
        // this.patientVisitDocuments.push({
        //     name: this.patientVisitData.documents[file].name,
        //     url: this.patientVisitData.documents[file].url,
        // })
        response.data.documents[file].created_by_name = await this.getUserName(
          response.data.documents[file].VisitDocument.created_by,
        )
        response.data.documents[file].uri =
          await this.getHistoryDocumentImageUrl(
            response.data.documents[file],
            this.patientVisitData.id,
          )
      }
      this.patientVisitDocuments = response.data.documents
      // console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocuments, 'dokumen kunjungan pasien')
    },
    async generateICD10(payloadData, primaryCode, secondaryCode) {
      let prompt = `\n Primary Diagnosis : `
      if (primaryCode.length) {
        primaryCode.forEach((e) => {
          prompt += `\n - ${e.code}`
        })
      }
      if (secondaryCode.length) {
        prompt += `\nSecondary Diagnosis :`
        secondaryCode.forEach((e) => {
          prompt += `\n - ${e.code} | ${e.STR}`
        })
      }
      this.ICD10Loading = true
      const assessment = payloadData
      const payload = {
        text: assessment + prompt,
      }
      console.log(assessment + prompt)
      const res = await generateICD10Code(payload)

      const data = res.data.codes
      if (data.length > 0) {
        const formattedData = data.map((item) => {
          return {
            ...item,
            code: item.skri + " | " + item.STR,
          }
        })
        // this.primary_condition = []
        // this.primary_condition_autoGenerated = []
        // this.primary_condition_autoGenerated = formattedData.slice(0, 1)

        if (formattedData.length > 0) {
          this.conditions_autoGenerated = formattedData
          console.log(formattedData, "tesini")
          this.primary_recommendations = []
          this.secondary_recommendations = []
          formattedData.forEach((e) => {
            if (e.type === "Primary") {
              this.primary_recommendations.push(e)
            } else {
              this.secondary_recommendations.push(e)
            }
          })
          console.log(this.conditions_autoGenerated, "niya")
        }
        // console.log(this.primary_condition_autoGenerated, 'primary_condition_autoGenerated')
        // console.log(this.secondary_conditions_autoGenerated, 'secondary_conditions_autoGenerated')
      } else {
        this.$notification.warning({
          message: "Peringatan",
          description: "Tidak ada kode ICD-10 yang dihasilkan",
          duration: 5,
        })
      }
      this.ICD10Loading = false
    },
    async getICD10Codes(search) {
      const ICD10response = await searchICD10Code(search)
      this.ICD10List = ICD10response.data.codes
      // console.log(this.ICD10List, 'ICD10 List')
    },
    async searchICD10(query) {
      if (query) {
        try {
          // console.log('Searching ICD10 codes:', query)
          const response = await searchICD10Code(query)
          this.ICD10List = response.data.codes
          // console.log("ICD10 codes:", this.ICD10List)
        } catch (error) {
          console.error("Error fetching ICD10 codes:", error)
          this.ICD10List = []
        }
      } else {
        this.ICD10List = []
      }
    },
    async generateICD9CM(payloadData) {
      console.log(payloadData, "iniplan")
      this.ICD9CMLoading = true
      try {
        const payload = {
          text: payloadData,
        }
        const res = await generateICD9CMCode(payload)
        const data = res.data.codes
        if (data.length > 0) {
          const formattedData = data.map((item) => ({
            ...item,
            code: item.CODE + " | " + item.STR,
          }))
          console.log(formattedData, "cek ini")
          this.tindakan_recommendations = formattedData
        } else {
          this.$notification.warning({
            message: "Peringatan",
            description: "Tidak ada kode ICD-9-CM yang dihasilkan",
            duration: 5,
          })
        }
      } catch (error) {
        console.error("Error generating ICD-9-CM:", error)
        this.$notification.error({
          message: "Gagal",
          description: "Terjadi kesalahan saat menghasilkan kode ICD-9-CM",
          duration: 5,
        })
      }
      this.ICD9CMLoading = false
    },
    async generateICD(assessment, plan, primaryCode, secondaryCode) {
      this.ICD10Loading = true
      let prompt = `\n Primary Diagnosis : `
      if (primaryCode.length) {
        primaryCode.forEach((e) => {
          prompt += `\n - ${e.code}`
        })
      }
      if (secondaryCode.length) {
        prompt += `\nSecondary Diagnosis :`
        secondaryCode.forEach((e) => {
          prompt += `\n - ${e.code} | ${e.STR}`
        })
      }
      const res = await generateICDCodes({
        assessment: assessment + prompt,
        plan,
      })
      const data = res.data.codes
      console.log(data, "iniicd")
      const icd10 = data.filter((e) => e.codeType === "ICD-10")
      const icd9 = data.filter((e) => e.codeType === "ICD-9")
      if (icd10.length > 0) {
        const formattedData = icd10.map((item) => {
          return {
            ...item,
            code: item.skri + " | " + item.STR,
          }
        })
        // this.primary_condition = []
        // this.primary_condition_autoGenerated = []
        // this.primary_condition_autoGenerated = formattedData.slice(0, 1)

        if (formattedData.length > 0) {
          this.conditions_autoGenerated = formattedData
          console.log(formattedData, "tesiniicd10")
          this.primary_recommendations = []
          this.secondary_recommendations = []
          formattedData.forEach((e) => {
            if (e.type === "Primary") {
              this.primary_recommendations.push(e)
            } else {
              this.secondary_recommendations.push(e)
            }
          })
          console.log(this.conditions_autoGenerated, "niya")
        }
        // console.log(this.primary_condition_autoGenerated, 'primary_condition_autoGenerated')
        // console.log(this.secondary_conditions_autoGenerated, 'secondary_conditions_autoGenerated')
      } else {
        this.$notification.warning({
          message: "Peringatan",
          description: "Tidak ada kode ICD-10 yang dihasilkan",
          duration: 5,
        })
      }

      if (icd9.length > 0) {
        const formattedData = icd9.map((item) => {
          return {
            ...item,
            code: item.skri + " | " + item.STR,
          }
        })
        console.log(formattedData, "iniformaticd9")
        this.tindakan_recommendations = formattedData
      } else {
        this.$notification.warning({
          message: "Peringatan",
          description: "Tidak ada kode ICD-9-CM yang dihasilkan",
          duration: 5,
        })
      }
      this.ICD10Loading = false
    },

    async getICD9CMCodes(search) {
      const ICD9CMresponse = await searchICD9CMCode(search)
      this.ICD9CMList = ICD9CMresponse.data.codes
      // console.log(this.ICD9CMList, 'ICD9CM List')
    },
    async searchICD9CM(query) {
      if (query) {
        try {
          // console.log('Searching ICD9CM codes:', query)
          const response = await searchICD9CMCode(query)
          this.ICD9CMList = response.data.codes
          console.log("ICD9CM codes:", this.ICD9CMList)
        } catch (error) {
          console.error("Error fetching ICD9CM codes:", error)
          this.ICD9CMList = []
        }
      } else {
        this.ICD9CMList = []
      }
    },

    async generateNewRecommendations() {
      const primaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === "primary",
      )
      const secondaryCode = this.patientVisitData.DoctorNote.Conditions.filter(
        (e) => e.condition_type === "secondary",
      )
      // await this.generateICD10(
      //   this.patientVisitData.DoctorNote.assessment,
      //   primaryCode,
      //   secondaryCode,
      // )
      // await this.generateICD9CM(this.patientVisitData.DoctorNote.plan)
      await this.generateICD(
        this.patientVisitData.DoctorNote.assessment,
        this.patientVisitData.DoctorNote.plan,
        primaryCode,
        secondaryCode,
      )
    },

    removePrimaryInput(index) {
      this.primary_condition.splice(index, 1)
    },
    removeSecondaryInput(index) {
      this.secondary_condition.splice(index, 1)
    },
    removeTindakanInput(index) {
      this.tindakan_condition.splice(index, 1)
    },
    async submitData() {
      const obj = {
        visit_id: this.visit_id,
        primary_condition: this.primary_condition,
        old_primary_condition: this.old_primary_condition,
        secondary_conditions: this.secondary_condition,
        old_secondary_conditions: this.old_secondary_condition,
        procedures: this.tindakan_condition,
        old_procedures: this.old_tindakan_condition,
      }
      await saveCoderNote(obj)
      this.$router.push("/")
      // console.log(this.primary_condition, 'submit')
      // console.log(this.secondary_condition, 'submit')
      // console.log(this.tindakan_condition, 'submit')
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}
.background-blue {
  background-color: #f6feff;
}
.table thead {
  border-bottom: 1px solid #e5e5e5;
}
.table th,
.table td {
  border: none;
  padding: 8px;
}
.SOAP-title-color {
  color: #428a94;
}
.image-container {
  position: relative;
  display: inline-block;
  margin: 0;
}
.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 999px;
}
.zoom-button:hover {
  background-color: rgba(255, 255, 255, 1);
}
</style>
